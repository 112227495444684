<template>
  <MilestoneTestView 
      v-model="testActivities"
      hideActionBtn
    />
</template>
<script>
import MilestoneTestView from '@/views/Tests/Milestones/MilestoneTestActivitiesView'
import Loader from '@/components/base/Loader'
import handleLoading from '@/mixins/loader.js'
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapState } = createNamespacedHelpers('milestone');
export default{
  components:{
    MilestoneTestView,
  },
  mixins:[handleLoading],
  data(){
    return{
      testActivities:{
        name: "",
        testRuns: [],
        testPlans: [],
        executionsProgress: 0,
        percentage: 0
      }
    }
  },
  computed:{
    ...mapState(['selectedMilestone']),
  },
  async created(){
    await this.init([this.findMilestone()]);
  },
  methods:{
    ...mapActions(['find']),
    async findMilestone() {
      const handle = this.$route.params.handle;
      await this.find({ handle: handle, projectKey: this.$route.params.key, uid: this.$route.params.id });
      this.testActivities.testPlans = this.selectedMilestone.testPlans;
      this.testActivities.testRuns = this.selectedMilestone.testRuns;
      this.testActivities.name = this.selectedMilestone.name;
      this.testActivities.executionsPercentage = this.selectedMilestone.percentage;
      this.testActivities.executionsProgress = this.selectedMilestone.executionsProgress;
    },
  },
}
</script>
